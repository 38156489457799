.page-footer {
    position: relative;
    background-image: url("../../../Magento_Theme/web/images/lilou_footer.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .footer-top, .footer-center {
        h3 {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 24px;
        }
        a {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: -5px;
                width: 0;
                height: 2px;
                background-color: currentcolor;
                transition: width 0.3s, left 0.3s;
                transform-origin: center center;
            }
            &:hover::after {
                width: 100%;
                left: 0;
            }
        }
        div[data-content-type="text"] {
            display: flex;
            flex-direction: column;
            gap: 16px;
            max-width: none;
            > ul {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
    .footer-center {
        .social-icons {
            display: flex;
            gap: 16px;
            @include respond-to('sm') {
                flex-direction: column;
                margin-bottom: 20px;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
        .flags {
            display: flex;
            gap: 16px;
            @include respond-to('sm') {
                flex-direction: column;
            }
            a {
                border: 1px solid black;
                svg {
                    width: 28px;
                    height: 20px;
                    transform: scaleY(1.4);
                }
            }
        }
    }
    .footer-bottom {
        margin-bottom: 0;
        padding: 24px 16px;
        background: $lilou-bg;
        p {
            font-size: 16px;
            padding: 0 15px;
            max-width: calc(1536px - 30px);
            margin: 0 auto;
            text-align: left;
        }
    }
    div[data-content-type="row"]:has(.footer-bottom) {
        margin: 30px 0 0 0;
        padding: 0;
        max-width: none;
    }
    .content {
        > div {
            div:nth-child(2) {
                @include respond-to('sm') {
                    display: flex;
                    flex-direction: row;
                }
                .flex {
                    @include respond-to('sm') {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .footer-content {
        z-index: 1;
        position: relative;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        @include respond-to('sm') {
            display: flex;
            flex-direction: column;
        }
        div:last-child {
            div {
                @include respond-to('sm') {
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
    .form.subscribe {
        h2 {
            font-family: 'Boska', sans-serif;
            font-size: 100px;
            font-weight: 400;
            line-height: 100px;
            color: $lilou-black;
        }
        .newsletter-subscribe-container {
            position: relative;
        }
        .btn-primary {
            position: absolute;
            width: auto;
            background: transparent;
            color: $lilou-black;
            border: 0;
            padding: 10px;
            right: 30px;
        }
    }
}



