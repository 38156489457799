.search-container {
    height: 100vh;
    top: 0;

    div {
        background: transparent;
    }

    @include respond-to('md') {
        top: 0;
        height: 100vh;
    }
    .search-content {
        position: relative;
        width: 100%;
        @include respond-to('md') {
            margin: 0
        }
        .close-button {
            right: 20px;
            top: 40px;
            z-index: 25;
        }
        .aa-InputWrapper {
            padding: 0;
            width: 100%;
        }
        .aa-Input {
            border: 0;
            box-shadow: unset;
            border-bottom: 1px solid #000;
            border-radius: 0;
            width: 100%;
            background: transparent;
            &:focus {
                box-shadow: unset;
                border-bottom: 2px solid #000;
            }
        }
        h3 {
            font-weight: 400;
            padding-left: 0;
        }
    }
}

.am-search-content {
    .grid {
        .product {
            .product-info {
                &:first-child {
                    > div {
                        flex: 1;

                        &:first-child {
                            flex-direction: column;
                            min-height: 108px;
                        }
                    }

                    .product-item {
                        .price-container {
                            flex: auto;
                        }
                    }
                }
            }
        }
    }
}
