.cms-index-index {
    #header {
        border: none;
        @include respond-to('md') {
            background: white;
        }

        .header-icons-menu {
            margin-bottom: 12px;
            @include respond-to('md') {
                margin-bottom: 0;
            }
        }
    }

    .pagebuilder-poster-content {
        h1 {
            overflow: hidden;
        }
    }

    .price-box {
        .price-final_price {
            margin-right: 10px;
            @include respond-to('sm') {
                margin-right: 0;
            }
        }
    }

    .personalization-icon {
        cursor: pointer;
    }

    section {
        h3 {
            font-size: 48px !important;
            font-family: Syne, sans-serif;
            line-height: 1.2;
        }
    }

    .slider-buttons {
        img {
            height: 20px;
        }
    }

    #header {
        .cls-1 {
            transition: all 0.5s;
            fill: #fff;
            @include respond-to('md') {
                fill: black;
            }
        }

        .item-submenu {
            a {
                color: #000;
            }
        }

        .level0 {
            a {
                color: #ffffff;
            }
        }

        &.scrolled-header {
            .level0 {
                a {
                    color: #000;
                }
            }
        }
    }

    #customer-menu, #menu-cart-icon, #menu-search-icon {
        svg {
            transition: all .3s;
            color: white;
            @include respond-to('md') {
                color: black;
            }
        }
    }

    .columns {
        width: 100%;
        max-width: unset;
        padding: 0;
        display: block;
    }

    #maincontent {
        margin-top: -200px;
    }

    .header-video-container {
        position: relative;
        width: 100%;
        max-height: 1300px;
        margin: 0 auto;
        overflow: hidden;
        min-height: 500px;
    }

    .header-video,
    .header-video img {
        width: 100%;
        height: auto;
        min-height: 500px;
    }

    .category-block-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #000;
        color: #fff;
        padding: 0;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 200px;
            background: #FFF7F2;
            z-index: 0;
        }

        .category-block-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 20px;
            position: relative;
            top: -100px;
            z-index: 1;
            @include respond-to('xs') {
                top: -50px;
            }

            .category-block-item {
                position: relative;
                width: 32%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                @include respond-to('sm') {
                    width: 45%;
                }

                h2 {
                    font-family: 'Boska', sans-serif;
                    font-size: 100px;
                    line-height: 100px;
                    font-weight: 400;
                    text-align: left;
                    margin: 0;
                    @include respond-to('md') {
                        font-size: 36px;
                        line-height: 36px;
                    }

                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    transform: translateY(30px); /* Move image downwards */
                }

                .category-block-item-text {
                    position: absolute;
                    bottom: 33px;
                    left: 35px;
                    color: #fff;
                    font-size: 33px;
                    font-weight: 400;
                    @include respond-to('md') {
                        font-size: 23px;
                    }
                    @include respond-to('xs') {
                        font-size: 18px;
                        bottom: 20px;
                        left: 20px;
                    }
                }
            }

            .category-block-text {
                display: flex;
                justify-content: left;
                align-items: center;
            }
        }
    }


    .custom-gift-block {
        .custom-gift-block__image img {
            object-fit: cover;
        }

        .custom-gift-block__content {
            h1 {
                font-family: 'Boska', sans-serif;
                font-size: 100px;
                line-height: 100px;
                @include respond-to('md') {
                    font-size: 36px;
                    line-height: 36px;
                }
            }

            .form-group {
                label {
                    color: $lilou-black;
                    width: 100px;
                }

                .button-group {
                    button {
                        &:hover {
                            background-color: #f0f0f0;
                        }
                    }
                }
            }
        }
    }

    .inspiration-block {
        @include respond-to('sm') {
            display: flex;
            flex-direction: column;
        }

        .inspiration-block__text {
            margin-top: 100px;

            h1 {
                font-family: 'Boska', sans-serif;
                font-size: 100px;
                line-height: 100px;
                @include respond-to('sm') {
                    font-size: 70px;
                }
            }

            a {
                color: $lilou-black;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .inspiration-element {
            position: relative;
        }

        .inspiration-item {
            position: relative;

            img {
                object-fit: cover;
            }
        }

        .inspiration-item.relative:nth-child(2) {
            margin-top: 100px;
        }

        .inspiration-item.relative:nth-child(3) {
            margin-top: 0;
        }

        .inspiration-item.relative:nth-child(5) {
            margin-top: -100px;
        }

        .inspiration-item.relative:nth-child(6) {
            margin-top: 100px;
        }
    }

    .video-block {
        position: relative;
        width: 100%;
        overflow: hidden;

        .content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: white;
            padding: 1rem;

            h1 {
                font-family: 'Boska', sans-serif;
                font-size: 3rem;
                line-height: 1.2;
                margin-bottom: 1rem;

                @include respond-to('sm') {
                    font-size: 4rem;
                }

                @include respond-to('md') {
                    font-size: 5rem;
                }
            }

            a {
                font-size: 1.25rem;
                text-decoration: underline;
                margin-top: 1rem;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }


    .gold-block {
        .gold-block__text {
            h1 {
                font-family: 'Boska', sans-serif;
                font-size: 3rem;
                line-height: 1.2;
                margin-bottom: 1rem;

                span {
                    font-size: 1.5rem;
                }

                @include respond-to('sm') {
                    font-size: 4rem;

                    span {
                        font-size: 2rem;
                    }
                }

                @include respond-to('md') {
                    font-size: 5rem;

                    span {
                        font-size: 2.5rem;
                    }
                }
            }

            p {
                font-size: 1.125rem;
                line-height: 1.6;
                color: #333;
            }
        }

        .gold-block__images {
            img {
                object-fit: cover;
            }
        }
    }

    .custom-gift-block__form {
        .form-group {
            @include respond-to('sm') {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            > div {
                @include respond-to('sm') {
                    margin: 5px 0;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    #maincontent {
        &.page-main {
            .main {
                [data-content-type=row][data-appearance=contained] {
                    max-width: none;
                    padding: 0;
                }
            }
        }
    }

    .slider-top {
        padding: 0;

        .row-full-width-inner {
            margin: 0;
            padding: 0;
            max-width: none;
            width: 100%;

            .glider-track {
                width: 100%;
            }

            .pagebuilder-slider {
                width: 100%;
            }
        }

        .pagebuilder-column-group {
            padding: 0;
            margin: 0;

            .pagebuilder-slider {
                .pagebuilder-overlay {
                    min-height: 300px;
                    background-color: transparent;
                    max-width: 1520px;
                    width: 100%;
                    display: flex;
                    margin: 0 auto;
                    font-size: 40px;
                    line-height: 40px;
                    color: white;
                    @include respond-to('md') {
                        padding: 0;
                    }
                }

                .glider {
                    height: 100%;

                    .glider-track {
                        height: 100%;

                        > div {
                            margin: 0;
                        }

                        .pagebuilder-slide-wrapper {
                            background-position: center center !important;
                            padding: 50px;
                            min-height: 730px !important;
                            display: flex;
                            align-items: center;
                            @include respond-to('md') {
                                padding: 30px;
                                min-height: 700px !important;
                                position: relative;
                            }
                            @include respond-to('sm') {
                                padding: 30px;
                                position: relative;
                            }

                            h2 {
                                * {
                                    font-size: 2.875rem;
                                    line-height: 3.5rem;
                                    color: inherit;
                                    margin-bottom: 2rem;
                                    max-width: 500px;
                                    font-weight: 400 !important;
                                }
                            }

                            p {
                                > * {
                                    white-space: pre-line;
                                    color: inherit;
                                    margin-top: 30px;
                                    font-weight: 300 !important;
                                    max-width: 520px;
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .carousel-nav {
                    background: transparent;

                    .glider-dots {
                        @include respond-to('md') {
                            display: none;
                        }

                        .glider-dot {
                            background: white;
                        }
                    }
                }
            }
        }
    }

    @include respond-to('md') {
        .grid.grid-cols-1 {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .grid.grid-cols-1 > div:first-child {
            width: 100%;
        }
        .grid.grid-cols-1 > .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr); /* Dwa elementy w rzędzie */
            gap: 10px;
        }
        .grid.grid-cols-1 > .grid > a {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
        .grid.grid-cols-1 > .grid > a img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
        .category-block-item-text {
            text-align: left;
        }
        .category-container-new {
            margin-top: 0;
        }
        [data-content-type=html] > div {
            padding-top: 20px;
            padding-bottom: 0;
        }
        .line1-slider-container {
            margin-bottom: 0;
        }
        .snap {
            > div {
                width: 80%;
            }
        }
    }
    @include respond-to('sm') {
        .pagebuilder-slide-button {
            max-width: 100% !important;
        }
        .pagebuilder-poster-overlay {
            position: absolute;
            width: calc(100% - 60px) !important;
            bottom: -40px;
        }
    }
}
