::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: unset;
}

::-webkit-scrollbar-thumb {
    background-color: $border-gray;
    outline: unset;
    border-radius: 10px;
}

svg {
    path {
        stroke-width: 1px;
    }
}

body {
    font-family: 'Syne', sans-serif;
    background: #fff0;
}

.hide {
    overflow: hidden;
    --removed-body-scroll-bar-size: 15px;
    overscroll-behavior: contain;
}

.line1-slider-container {
    margin-bottom: 100px;
    .title-font {
        font-size: 50px;
        font-weight: 400;
        line-height: 100px;
        @include respond-to('md') {
            font-size: 36px;
            line-height: 40px;
        }
        .slider-buttons {
            margin-top: 10px;
            @include respond-to('md') {
                svg {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .product-info {
        .mt-auto.pt-3.flex.flex-wrap.items-center {
            display: none;
        }
    }
    .product-item-link {
        font-weight: 400;
        font-size: 16px;
        text-align: left;
        display: block;
    }
}

.checklist {
    list-style-type: none;

    li {
        position: relative;
        padding-left: 40px;
        margin-bottom: 15px;
        &:before {
            content: url("../../svg/checklist-item.svg");
            position: absolute;
            left: 5px;
            top: 3px;
        }
    }
}

.grid.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}
.personalization-icon, .setup-config-icon {
    img {
        height: 25px;
    }
}
.checkout-cart-index .cart.item .message.error {
    background-color: rgb(239 68 68);
    color: #fff;
    border: unset;
    box-shadow: unset;
    svg {
        color: #fff;
    }
}
.message.error {
    background-color: rgb(239 68 68);
    color: #fff;
    svg {
        color: #fff;
    }
}
.message.success {
    background-color: rgb(16 185 129);
    color: #fff;
    svg {
        color: #fff;
    }
}

.checkout-cart-index .cart.item .message.success  {
    background-color: rgb(16 185 129);
    color: #fff;
    border: unset;
    box-shadow: unset;
    svg {
        color: #fff;
    }
}
.loader-image {
    max-height: 100px;
}
body {
    .ec-gtm-cookie-directive > div {
        border-radius: 0px;

        > div > div a.action.accept-all, > div > div a.action.accept {
            background: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 0;

            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;

            &:hover {
                background-color: $hoverBtn;
                border: 1px solid $hoverBtn;
            }

            &:disabled,
            &[disabled] {
                background-color: $border-gray;
                border-color: $border-gray;
                color: #666;
                cursor: not-allowed;
            }
        }
        > div > div a.action.decline, > div > div a.action.customize{
            background: transparent;
            color: #000;
            border: 1px solid #000;
            border-radius: 0;
            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;
        }
        > div > div .ec-gtm-cookie-directive-note-toggle {
            color: #000;
            font-weight: 700;
        }
    }
    #algoliaRecommend .recommend-component {
        margin-bottom: 0;
    }
}
