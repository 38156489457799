@font-face {
    font-family: 'Boska';
    src: url('../../fonts/Boska.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Syne';
    src: url('../../fonts/Syne.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
