#notice-cookie-block {
    > div {
        display: flex;
    }
}


.ec-gtm-cookie-directive {
    display: flex;
    bottom: 0;
    opacity: 1;
    align-items: center;
    height: 100vh;
    background: rgba(0,0,0,0.6);

    > div {
        margin: 0 auto;

        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 10px;

            &:before {
                content: "";
                width: 100%;
                height: 80px;
                background: url(https://lilouparis.com/media/wysiwyg/custom/7sujxw0r6utm7d12gv3_1_1_.png) no-repeat;
                text-align: left;
            }

            > div:last-child {
                display: flex;
                flex-direction: row-reverse;
                width: 100%;
                margin-bottom: 0;
                flex-wrap: wrap;

                a.action {
                    width: calc(33.33% - 15px);
                }

                a.action.refuse {
                    width: 100%;
                    text-align: left;
                    order: 0;
                    margin-top: 0;
                }

                a.action.decline {
                    background: $cookie-bg;
                    color: white;
                    order: 3;
                }

                a.action.customize {
                    background: $cookie-bg;
                    color: white;
                    order: 2;
                }

                a.action.accept-all {
                    order: 1;
                }
            }
        }
    }
}

@include respond-to('md') {
    .ec-gtm-cookie-directive {
        display: flex;
        height: 100vh;
        width: 100%;
        bottom: 0;
        opacity: 1;

        > div {
            width: 100%;
            max-width: none;

            > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                height: 100vh;
                padding: 10px;

                &:before {
                    content: "";
                    width: 100%;
                    height: 80px;
                    background: url(https://lilouparis.com/media/wysiwyg/custom/7sujxw0r6utm7d12gv3_1_1_.png) no-repeat;
                    text-align: left;
                }

                > div:last-child {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-bottom: 30px;

                    a.action {
                        width: 100%;
                    }

                    a.action.decline {
                        background: $cookie-bg;
                        color: white;
                    }

                    a.action.customize {
                        background: $cookie-bg;
                        color: white;
                    }
                }
            }
        }
    }
}
