body.account {
    .account-nav {
        box-shadow: unset;
        padding: 0;
        .delimiter {
            display: none;
        }
        .nav.items {
            border-top: 1px solid $border-gray;
        }
        .nav.item {
            padding: 10px;
            border-bottom: 1px solid $border-gray;

            &.current {
                background-color: $cartBg;
                strong {
                    text-decoration: unset;
                }
            }
        }
    }
    .street {
        display: flex;
        gap: 1rem;
        .field.field-reserved {
            width: 50%;
            @include respond-to('sm') {
                width: 100%;
            }
        }
        .field.additional {
            width: 25%;
            @include respond-to('sm') {
                width: 50%;
            }
        }
    }
    button.account-nav-title {
        display: none;
        @include respond-to('sm') {
            display: block;
        }
    }
    .actions-toolbar {
        border: 0;
        padding-bottom: 50px;
        .action.primary {
            background: #000;
            color: #fff;
            border: 1px solid #000;
            border-radius: 0;
            padding: 15px;
            font-weight: 700;
            text-align: center;
            justify-content: center;
            box-shadow: unset;
            &:hover {
                background-color: $hoverBtn;
                border: 1px solid $hoverBtn;
            }

            &:disabled,
            &[disabled] {
                background-color: $border-gray;
                border-color: $border-gray;
                color: #666;
                cursor: not-allowed;
            }
        }
    }
    .wishlist-toolbar {
        display: none;
    }
    .form-wishlist-items {
        .products-grid.wishlist {
            .product-item-info {
                box-shadow: unset;
            }
            .field.qty, .field.comment-box {
                display: none;
            }
            .product-image-container {
                aspect-ratio: unset;
                background-color: $cartBg;
            }
            .product-item-inner {
                display: flex;
                gap: 1rem;
                flex-direction: row-reverse;
                .product-item-actions {
                    a {
                        width: unset;
                        padding: 17px;
                    }
                }
            }
        }
        .actions-toolbar {
            button {
                width: unset;
            }
        }
    }
    .block-reorder {
        display: none;
    }
    .order-details-items {
        .card {
            box-shadow: unset;
        }
    }
    .order-products-toolbar {
        .limiter, .toolbar-amount {
            display: none;
        }
    }
    .form-edit-account {
        .field.choice {
            label {
                margin-top: 0;
            }
        }
    }
}
